<template>
  <el-dialog
    :visible.sync="selfDialogShow"
    width="25%"
    top="25vh"
    :title="$t('configurationManagement.addconfiguration.title')"
    :close-on-click-modal="false"
    :show-close="true"
    custom-class="create_dashboard"
  >
    <el-form
      ref="addDatachartForm"
      label-width="150px"
      :model="addChartForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item
        prop="name"
        :label="$t('configurationManagement.addconfiguration.name')"
      >
        <el-input v-model="addChartForm.name"> </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('configurationManagement.addconfiguration.desc')"
      >
        <el-input v-model="addChartForm.description"> </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="hideDialog">{{
        $t("btn.cancelBtn")
      }}</el-button>
      <el-button type="primary" class="button-confirm" @click="createChart">{{
        $t("btn.newBtn")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addConfiguration } from "@/network/configurationManagement/index.js";
export default {
  components: {},
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addChartForm: {
        name: "",
        description: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    selfDialogShow: {
      get() {
        return this.dialogShow;
      },
      set(val) {
        this.$emit("changeDialogShow", val);
        return val;
      },
    },
  },
  methods: {
    createChart() {
      this.$refs.addDatachartForm.validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: this.addChartForm,
          };
          addConfiguration(params)
            .then((configId) => {
              this.$emit("getAllConfig", () => {
                this.$emit("addChartSucess", configId);
              });
            })
            .then(() => {
              this.addChartForm = {
                name: "",
                description: "",
              };
              this.$emit("changeDialogShow", false);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          return false;
        }
      });
    },
    hideDialog() {
      this.$emit("changeDialogShow", false);
    },
  },
};
</script>

<style></style>
