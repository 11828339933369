<template>
  <div class="datachart_default">
    <div class="pic">
      <img src="@/assets/img/nothing.png" alt="" />
    </div>
    <div class="tip">{{ $t("placeholder.table1") }}</div>
    <span>
      <el-button
        v-limits-of-authority="'CONFIG_ITEM_CREATE_EDIT_COPY'"
        type="primary"
        size="medium"
        :disabled="limits_of_authority_disable('CONFIG_ITEM_CREATE_EDIT_COPY')"
        @click="show_dialog"
      >
        <i class="iconfont icon-add"></i>&nbsp;&nbsp;{{ $t("btn.newBtn") }}
      </el-button>
    </span>
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    limits_of_authority_disable,
    show_dialog() {
      this.$emit("changeDialogShow", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.datachart_default {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .tip {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .pic svg {
    font-size: 550px;
  }
}
</style>
