<template>
  <div class="data-board-wrap">
    <div v-if="!configurationOptions.length">
      <nodata
        v-if="configurationOptions.length == 0"
        @changeDialogShow="changeDialogShow"
      />
    </div>
    <div v-else>
      <hasdata
        ref="hasdata"
        :configuration-options="configurationOptions"
        @changeDialogShow="changeDialogShow"
        @getAllConfig="getAllConfig"
      ></hasdata>
    </div>
    <addConfigurationDialog
      :dialog-show="dialogShow"
      @changeDialogShow="changeDialogShow"
      @getAllConfig="getAllConfig"
      @addChartSucess="addChartSucess"
    ></addConfigurationDialog>
  </div>
</template>

<script>
import nodata from "./components/nodata.vue";
import hasdata from "./components/hasdata.vue";
import addConfigurationDialog from "./components/addConfigurationDialog.vue";
import { getAllconfiguration } from "@/network/configurationManagement/index.js";
export default {
  components: {
    nodata,
    hasdata,
    addConfigurationDialog,
  },
  props: {},
  data() {
    return {
      // 新增数据报表弹窗是否显示
      dialogShow: false,
      //下拉框内容
      configurationOptions: [],
    };
  },
  mounted() {
    this.getAllConfig();
  },
  methods: {
    async getAllConfig(callBack) {
      await getAllconfiguration(this.get_pid())
        .then((res) => {
          this.configurationOptions = [...res];
          this.$nextTick(() => {
            callBack ? callBack() : "";
          });
        })
        .catch((e) => console.log(e));
    },
    // 成功新增图表
    addChartSucess(id) {
      this.$refs.hasdata.selectConfig(id);
    },
    // 新增弹窗是否展示事件
    changeDialogShow(show) {
      this.dialogShow = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-board-wrap {
  height: 100%;
  & > div {
    height: 100%;
  }
}
</style>
